import React, {useCallback, useState} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {NavLink} from 'react-router-dom';
import {IconPhone} from '../../icons';
import {Modal} from '../modal';
import {Button, Input, InputPhone} from '../../fields';
import {buttonTypes} from '../../fields/types';
import {ConfirmationCheckbox} from '../confirmationCheckbox';
import {IRequestLearnMore, sensRequestLearnMore} from '../../store/app.slice';
import {useAppDispatch} from '../../hooks';
const bg = require('../../assets/images/defailt-card-bg.png');

const schema = yup.object({
    company_name: yup.string().required(),
    name: yup.string().required(),
    email: yup.string().email().required(),
    phone_number: yup.string().required()
});

export const DefaultBanner = () => {
    const dispatch = useAppDispatch();
    const [isOpen, setOpen] = useState<boolean>(false);
    const [phone, setPhone] = useState<string>('');
    const [isDisabled, setDisabled] = useState<boolean>(false);

    const handlePhone = useCallback((phone: string) => {
        setPhone(phone);
        setValue('phone_number', phone);
    }, []);

    const {control, register, setValue, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const submit = handleSubmit(values => {
        const data: IRequestLearnMore = {
            name: values.name,
            phone_number: values.phone_number,
            email: values.email,
            company_name: values.company_name
        };

        dispatch(sensRequestLearnMore({data}));
        clearFields();
    });

    const clearFields = () => {
        setValue('name', '');
        setValue('phone_number', '');
        setValue('email', '');
        setValue('company_name', '');
        setPhone('');
        setOpen(false);
    };

    return (
        <div className={'default-banner'} style={{backgroundImage: `url(${bg})`}}>
            <p className={'default-banner__text'}>Здесь точно ваша компания будет заметнее!</p>
            <button className={'default-banner__button'} onClick={() => setOpen(true)}>Узнать подробнее</button>
            <Modal isOpen={isOpen} handleClose={() => setOpen(false)}>
                <div className={'default-banner-form'}>
                    <div className={'default-banner-form__box'}>
                        <Input
                            placeholder={'Компания'}
                            control={control}
                            register={register}
                            fieldName={'company_name'}
                            isRequired={true}
                        />
                    </div>
                    <div className={'default-banner-form__box'}>
                        <Input
                            placeholder={'Имя'}
                            control={control}
                            register={register}
                            fieldName={'name'}
                            isRequired={true}
                        />
                    </div>
                    <div className={'default-banner-form__box'}>
                        <Input
                            placeholder={'E-mail'}
                            control={control}
                            register={register}
                            fieldName={'email'}
                            isRequired={true}
                        />
                    </div>
                    <div className={'default-banner-form__box'}>
                        <InputPhone
                            isRequired={true}
                            phone={phone}
                            onChange={handlePhone}/>
                    </div>
                    <div className={'default-banner-form__box'}>
                        <ConfirmationCheckbox
                            isChecked={isDisabled}
                            handleChecked={() => setDisabled(!isDisabled)}
                            text={<>Нажимая кнопку «Отправить», я даю свое <NavLink to={'/policy'}>согласие на обработку моих персональных данных</NavLink>, в
                                соответствии с Федеральным законом от 27.07.2006 года №152-ФЗ «О персональных данных», на
                                условиях и
                                для целей, определенных в Согласии на обработку персональных данных</>
                            }
                        />
                    </div>
                    <Button
                        icon={null}
                        text={'Позвоните мне'}
                        disabled={isDisabled}
                        handler={submit}
                        size={buttonTypes.xl}
                        outline={false}/>
                </div>
            </Modal>
        </div>
    );
};
